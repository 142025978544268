@import "/src/styles/variables.scss";

.overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $darkest;
    color: white;
    z-index: 9999;
}
.spinner{
    width: 5rem;
    height: 5rem;
}