@import "/src/styles/variables.scss";

.container {
  width: 100%;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  // overflow: hidden;
  background-color: rgb(233, 233, 233);
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.pagecontentwrapper {
  min-width: 0;
  // height: 100%;
  min-height: 100 - $navbar-height;
}
.pagecontentcolumn {
  min-width: 0;
  box-shadow: inset 0 0.5em 1.5em rgb(0 0 0 / 10%),
    inset 0 0.125em 0.5em rgb(0 0 0 / 15%);
}
