@import "/src/styles/variables.scss";

.footer {
	color: white;
	background-color: #1f1f1f;
	min-height: $footer-height;
}
.mainUserful{
	margin-inline: 0.5rem;
}
.userful{
	a{
		text-decoration: none;
		color: gainsboro;
		margin-right: 0.5rem;
		font-size: small;
	}
}

@media (max-width: 991px) {
	.userful, .mainUserful {
	  text-align: center;
	  font-size: 13pt;
	}
	.mainUserful{
		margin-inline: 0rem;
	}
  }

.endFooter{
	background-color: #121212;
}
.endFooterText{
	color:rgb(122, 122, 122) ;
	font-size: x-small;
}