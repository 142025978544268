@import "/src/styles/variables.scss";

// .hover1:hover {
//   .card-body {
//     color: #fff;
//     background-color: $dark;
//   }
//   .card-footer {
//     background-color: $dark;
//   }
// }
.lazy-load-image-background {
  text-align: center;
}
// .hover-warning:hover {
//   .card-body,
//   .card-footer {
//     background-color: $warning;
//     color: #fff;
//   }
// }
// .hover-primary:hover {
//   .card-body,
//   .card-footer {
//     background-color: $primary;
//     color: #fff;
//   }
// }
