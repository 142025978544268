/* Color Overrides */

$primary: #2874a6;
$dark: #942922;
$darkest: #662018;
$success: #8cce38;
$warning: #ff7300;
$transparent: transparent;
$light: transparent;
$main-blue: #284754;
$alternative: #123155;

// $nav-pills-link-active-color: $primary;

$login-brand-width: 300px;

$navbar-height: 11vh;
$footer-height: 20vh;

$large-screen: 1200px;
$medium-screen: 991px;
