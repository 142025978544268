@import "variables.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "./glifos-theme.scss";
@import "./custom.scss";

html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.scrollSection {
  scroll-snap-align: start;
}
.scrollSectionHiden {
  scroll-snap-align: none;
}

@media (max-width: $medium-screen) {
  .fill_md_btn {
    text-align: center;
    button {
      width: 100%;
    }
    a {
      width: 100%;
    }
  }
}
@media (max-width: 576px) {
  .mobile_center {
    text-align: center;
    button {
      width: 100%;
    }
    a {
      width: 100%;
    }
  }
  .flex_sm_column {
    flex-direction: column !important;
  }

  .align_items_sm_start {
    align-items: flex-start !important;
  }
}

.text_justify {
  text-align: justify;
}

#root {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Roboto, Arial,
    sans-serif;
}

.custom-hr {
  border: 0;
  height: 5px;
  background: rgba(31, 34, 58);
}
