@import "/src/styles/variables.scss";

.bandImage {
  height: 50px;
  width: auto;
}
.mainNav {
  min-height: $navbar-height;
  z-index: 10;
}

.nav-item:hover .dropdown-menu {
  display: block !important;
}
