@import "/src/styles/variables.scss";

.record {
  min-height: 400px;
  background-color: #fff;
  padding-inline: 1rem;
  padding-block: 1rem;
  transition: all ease 0.2s;
  box-shadow: none;
  transform: scale(1);
}
.record:hover {
  transform: scale(1.01);
  z-index: 10000;
  box-shadow: 2px 3px 5px grey;
}
.footer {
  background-color: #fff !important;
  display: flex;
  align-items: end;
  justify-content: end;
  padding-bottom: 0rem !important;
  margin-bottom: 0rem !important;
}
.header {
  background-color: #fff !important;
}
.cardImg {
  width: auto !important;
  max-width: 100% !important;
  // height: auto;
  max-height: 180px;
}
.description {
  font-size: 10pt;
  margin: 0;
}
.recordCommunity {
  min-height: 310px !important;
  background-color: #fff;
  padding-inline: 1rem;
  padding-block: 1rem;
  transition: all ease 0.2s;
  box-shadow: none;
  transform: scale(1);
}
.recordCommunity:hover {
  transform: scale(1.01);
  z-index: 10000;
  box-shadow: 2px 3px 5px grey;
}
.recordTitle {
  font-size: 12pt !important;
}
.cardHeader {
  background-color: #fff !important;
  min-height: 200px !important;
  text-align: center;
}

@media (min-width: $medium-screen) {
  .cardpading {
    padding-inline: 1rem !important;
    // padding-block: 0.5rem !important;
  }
}
