@import "variables.scss";
// custom style for dark mode navbar
.navbar-dark {
  .dropdown-menu {
    background-color: $dark;
    .dropdown-item {
      &:hover {
        background-color: $darkest;
      }
    }
  }
}

.navbar.bg-dark {
  a,
  span {
    color: white;
  }
}

// custom style for dark mode navbar
.navbar-ligth {
  .dropdown-menu {
    background-color: $light;
    .dropdown-item {
      &:hover {
        color: #000;
      }
      background-color: #fff;
      color: #000;
    }
  }
  .nav-link:hover,
  .nav-link:focus {
    color: #fff;
  }
  .navbar-brand:hover {
    color: #fff;
  }
  .nav-link,
  .navbar-brand,
  .btn-ligth {
    color: #fff;
  }
}

.navbar-ligt {
  .bg-ligth {
    a,
    span {
      color: #000;
    }
  }
}
// custom style for ligth mode navbar

.navbar.bg-transparent {
  a,
  span {
    color: black;
  }
}
.offcanvas-transparent {
  background-color: #fff !important;
}

// custom style for alternative mode navbar
.bg-alternative {
  background-color: $alternative;
  color: white;
}
.navbar.bg-alternative {
  .navbar-brand,
  .nav-link {
    color: white;
  }
  .navbar-nav .nav-link.active,
  .navbar-nav .nav-link.show {
    color: #edebe9;
  }
  a,
  span {
    color: white;
  }
  .btn-alternative {
    color: white !important;
  }
}
.navbar-alternative {
  .dropdown-menu {
    background-color: $alternative;
    .dropdown-item {
      &:hover {
        background-color: $alternative;
        color: white;
      }
    }
  }
}
.offcanvas-alternative {
  a,
  .btn-ligth {
    color: #fff !important;
  }
  .btn-ligth:hover,
  a:hover {
    color: #9b9999 !important;
  }
}
// * pading sections
.paddingPageSection {
  padding-block: 3rem;
  padding-inline: 3rem;
  padding-right: 3rem;
}
@media (max-width: $large-screen) {
  .paddingPageSection {
    padding-block: 1rem;
    padding-inline: 0.3rem;
  }
}

// * pading sections
.paddingPageSectionX {
  padding-inline: 3rem;
  padding-right: 3rem;
}
.paddingPageSectionX1 {
  padding-inline: 3rem;
  padding-right: 0rem;
}
@media (max-width: $large-screen) {
  .paddingPageSectionX,
  .paddingPageSectionX1 {
    padding-inline: 1rem;
  }
}

.donor_img {
  width: 100%;
  height: auto;
}

.bgGray {
  background-color: #edebe9;
}

// PAGINATION
.page-link.active,
.active > .page-link {
  background-color: #434141;
  border-color: #000;
}
.page-link:hover {
  color: #000;
}
.page-link {
  color: #000;
}
// PAGINATION

// CUSTOM CHECKBOX COLORS
.check-warning {
  .form-check-input:checked {
    background-color: $warning !important;
    border-color: #d56408 !important;
  }
  .form-check-input:focus {
    border-color: #f5984c !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255 110 16 / 25%) !important;
  }
}
.check-danger {
  .form-check-input:checked {
    background-color: #dc3545 !important;
    border-color: #be1929 !important;
  }
  .form-check-input:focus {
    border-color: #f15464 !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(224, 35, 10, 0.25) !important;
  }
}
.check-black {
  .form-check-input:checked {
    background-color: #5d5b66 !important;
    border-color: #000 !important;
  }
  .form-check-input:focus {
    border-color: #5d5b66 !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(70, 62, 61, 0.25) !important;
  }
}
// CUSTOM CHECKBOX COLORS
.cursor-pointer {
  cursor: pointer !important;
}
// PAGINATION
.page-link.active,
.active > .page-link {
  z-index: 0;
}

@media (max-width: 576px) {
  .sm-padding-05 {
    padding-inline: 0.5rem;
  }
}
