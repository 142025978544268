@import "/src/styles/variables.scss";
.toolkit {
  color: #202020;
  text-transform: uppercase;
  font-size: inherit;
  position: relative;
  background-color: #e6e6e6;
  padding: 0;
  span {
    margin: 0;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-decoration: none;
    color: #202020;
  }
}

.toolkit:hover {
  background: #fff;
  color: #222;
}

.searchBtn {
  font-size: 2rem;
  position: relative;
  color: white;
  a {
    color: white;
    text-decoration: none;
  }
  div {
    background-color: #981c00;
  }
}

.currentTol {
  background: #fff;
  color: #222;
}

.top {
  padding-top: 3rem;
}
@media (max-width: $medium-screen) {
  .top {
    padding-top: 1rem;
  }
}
