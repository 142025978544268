.arrowbottom {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid white;
  margin-left: 50%;
  margin-top: -3px;
  position: relative;
}
